import * as React from "react"
import { Link } from 'gatsby'
import Footer from '../components/Footer'

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  width: "100%",
  textAlign: "center"
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyleContainer = {
  textAlign: "center"
}
const listStyles = {
  display: "inline-block",
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType:'none',
}
const listItemStyles = {
  textAlign: "left",
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const noLinkStyle = {
  textDecoration: "none"
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "TypeScript Documentation",
  url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative" as "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    text: "Step 1: Get to a Safe Place and Call the Police",
    url: "",
    description:
      "If your phone has been stolen, try asking a kind stranger for help.",
    color: "#E95800",
  },
  {
    text: "Step 2: Call your credit card companies and freeze your cards",
    url: "BankNumbers",
    description:
      "Each major credit card customer support number can be found by clicking on Step 2.",
    color: "#1099A8",
  },
  {
    text: "Step 3: Find my devices and erase the devices",
    url: "https://www.icloud.com/find",
    description:
      "Whatever you do, do not attempt to find and confront the criminal.",
    color: "#BC027F",
  },
  {
    text: "Step 4: File Police Report",
    url: "",
    description:
      "This will come in handy for the next step.",
    color: "#0D96F2",
  },
  {
    text: "Step 5: Either through insurance or through your credit card companies you can file a claim for reimbursement",
    url: "https://www.nerdwallet.com/article/credit-cards/credit-card-purchase-protection",
    description:
      "Some banks may Purchase Protection for stolen items if you've bought via credit card.",
    color: "#8EB814",
  }
]

// markup
const IndexPage = () => {
  return (
    <div style={{margin: '0'}}>
    <main style={pageStyles}>
      <title>Home Page</title>
      <h1 style={headingStyles}>
        Have you recently had your
        <br />
        <span style={headingAccentStyles}> Purse Jacked </span>
        👤💰 ?
      </h1>
      <div style={listStyleContainer}>
        <ul style={listStyles}>
          {links.map(link => (
            <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
              <span>
                <Link
                  style={{...noLinkStyle, color: link.color}}
                  to={`${link.url}`}
                >
                  {link.text}
                </Link>
                <p style={descriptionStyle}>{link.description}</p>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </main>
      <Footer/>
    </div>
  )
}

export default IndexPage
