import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faCopyright } from '@fortawesome/free-solid-svg-icons'


const footerStyles = {
    background: '#f5f5f5',
    height: '50px'
}

const footerContentWrapper = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column'
}

const footerContentStyle = {
    padding: '10px, 0, 0, 0',
    textAlign: 'center',
    color: 'rgb(102, 51, 153)'
}

const footerIconStyle = {color: '#7d869c'}

const Footer = () => {
    return (
        <footer style={footerStyles}>
            <div style={footerContentWrapper}>
                <p style={footerContentStyle}>
                    Made With <FontAwesomeIcon style= {footerIconStyle}icon={faHeart} size="1x" /> in Austin, TX
                </p>
                <small style={{...footerContentStyle, fontFamily: 'Helvetica'}}>
                    <FontAwesomeIcon style= {{...footerIconStyle, marginRight: '1px'}}icon={faCopyright} size="1x" />
                    WolfDogPack, LLC 2022
                </small>
            </div>
        </footer>
    )
}

export default Footer